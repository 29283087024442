import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'
import Code from '../../components/Code'

const meta = {
  title: 'React Integration',
  image: '274877907168',
  path: '/docs/reactjs-integration',
  description: 'How to integrate Tueri.io into your React app',
  keywords: 'react image optimization, tueri react docs'
}

const ReactIntegration = ({ data }) => (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>

        <Hero
            large
            title={ meta.title }
            subTitle={ meta.description }
        />

        <a className='githubLink' target="_blank" rel="noopener noreferrer" href='https://github.com/tueri-io/react-tueri'><span><Img src='https://cdn.tueri.io/274877907174/github-logo_318-53553.jpg' alt='View on GitHub'/></span>View on GitHub</a>

       <p>
         Integrate Tueri.io into your React application in minutes.
       </p>

        <h2>Installation</h2>
        <Code type='cli'>npm i @tueri/react</Code>
        or
        <Code type='cli'>yarn add @tueri/react</Code>

        <h2>Import Tueri</h2>
        Wrap your app with the <code>{`<TueriProvider/>`}</code>
        <Code type='jsx'>{`import TueriProvider from '@tueri/react'

ReactDOM.render(
    <TueriProvider>
        <MyRootComponent />
    </TueriProvider>,
    document.getElementById('root)
)`}</Code>

    <h2>Usage</h2>
    Use the Tueri <code>{`<Img/>`}</code> component
    <Code type='jsx'>{`import { Img } from '@tueri/react'

function MyComponent() {
    return(
        <Img src='https://cdn.tueri.io/...' alt='Alternate Text' />
    )
}`}</Code>

    Tueri automatically detects the dimensions of the parent element, then generates and returns your image in exactly the right dimensions to fill the parent element.

    <h3>The {`<Img />`} component</h3>

    <h4>Props</h4>
    <ul>
      <li>src: String (REQUIRED)</li>
      <li>alt: String (REQUIRED)</li>
      <li>options: Object (optional)
        <ul>
          <li>Default: {`w: <auto-calculated>`}</li>
        </ul>
      </li>
    </ul>

    </Layout>
)

export default ReactIntegration