import React from 'react'

export default function Code({ type = 'text', children }) {
    return (
        <div className='gatsby-highlight' data-language={ type }>
            <pre className={`language-${type}`} >
                <code className={`language-${type}`}>
                    { children }
                </code>
            </pre>
        </div>
    )
}